// import React from "react"
// import PropTypes from "prop-types"
// import { Route, Redirect } from "react-router-dom"
// import * as Session from "../../helpers/session_helper"

// const Authmiddleware = ({
//   component: Component,
//   layout: Layout,
//   isAuthProtected,
//   ...rest
// }) => (
//   <Route
//     // {...rest}
    
//     render={props => {

//       if (isAuthProtected && !Session.isLogedIn()) {
//         return (
//           <Redirect
//             to={{ pathname: "/login", state: { from: props.location } }}
//           />
//         )
//       }

//       return (
        
//         <Layout>
//           <Component {...props} />
//         </Layout>
//       )
//     }}
//   />
// )

// Authmiddleware.propTypes = {
//   isAuthProtected: PropTypes.bool,
//   component: PropTypes.any,
//   location: PropTypes.object,
//   layout: PropTypes.any,
// }

// export default Authmiddleware




import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import * as Session from "../../helpers/session_helper";

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  roles,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !Session.isLogedIn()) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      const userRole = Session.getUserRole(); // Assuming you have a method to get the user role

      if (isAuthProtected && roles && roles.length>0 && !roles.includes(userRole)) {
        return <Redirect to="/" />;
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
  roles: PropTypes.array,
};

export default Authmiddleware;