import React, { useState, useEffect, useRef } from "react"


import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
import './model.scss'
const moment = require('moment');

const Model = (props) => {
    const history = useHistory()
    const [updateData, setUpdateData] = useState(false)
    const [courses, setCourses] = useState([]);
    const [courseId, setCourseId] = useState(null);

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        // else if (!props.id && files.length == 0) {
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        else {
            if (props.id && props.id == updateData.id) {
                const jsonData = {
                    id:updateData.id,
                    serviceName:values['serviceName'],
                    serviceCode:values['serviceCode'],
                    serviceDescription:values['serviceDescription'],
                    courseId:courseId,
                    paymentPolicy:{
                        id:updateData.paymentPolicy.id,
                        haveApplicationFee : true,
                        applicationFee : values['serviceFee'],
                        installmentBasedPayment : false,
                        numberOfInstallment : 0,
                        paymentPolicyIsActive : true
                    }
                }

                await Axios.patch(`/service/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200) {
                                toast.success('Updated successfully!', {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,
                                });
                                props.handleCallback(response.data)
                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    serviceName:values['serviceName'],
                    serviceCode:values['serviceCode'],
                    serviceDescription:values['serviceDescription'],
                    courseId:courseId,
                    paymentPolicy:{
                        haveApplicationFee : true,
                        applicationFee : values['serviceFee'],
                        installmentBasedPayment : false,
                        numberOfInstallment : 0,
                        paymentPolicyIsActive : true
                    }
                }

                await Axios.post("/service", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {

                            toast.success('Service created successfully!', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                            setTimeout(() => {
                                history.push("/service-list");
                            }, 1000);

                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }

        }
        
        window.hLoader();
    }
    useEffect(async () => {
        if (props.id && props.id > 0) {
            await Axios.get(`/service/details/id/${props.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        setCourseId(response.data.data.courseId);
                        setUpdateData(response.data.data);
                    }
                })
        }

    }, [props.id]);

    useEffect(async () => {
        await Axios.get("/open/course/dropdown")
        .then((response) => {
            if (response.data.status === 200) {
                let activeCourses = response.data.data;
                setCourses(activeCourses);
            }
        });
    }, []);


    return (
        <Row>
        {(!props.id || props.id===0 || (props.id>0 && updateData) ) &&
        <Col xl="12">
            <Card>
                <CardBody>
                    <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    <Row>
                        <Col md="6">
                            <div className="mb-3">
                                <Label htmlFor="serviceName">Service Name</Label>
                                <AvField
                                    name="serviceName"
                                    defaultValue={updateData['serviceName']}
                                    placeholder="Service Name"
                                    type="text"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Please provide a Service Name"
                                    id="serviceName"
                                />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label htmlFor="serviceCode">Service Code</Label>
                                <AvField
                                    name="serviceCode"
                                    defaultValue={updateData['serviceCode']}
                                    placeholder="S-01"
                                    type="text"
                                    className="form-control"
                                    validate={{ required: { value: false } }}
                                    errorMessage="Please provide a Service Code"
                                    id="serviceCode"
                                />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label htmlFor="serviceFee">Service Fee</Label>
                                <AvField
                                    name="serviceFee"
                                    defaultValue={updateData.paymentPolicy?.applicationFee}
                                    placeholder="0"
                                    type="number"
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Please provide a Service Fee"
                                    id="serviceFee"
                                />
                            </div>
                        </Col>
                        <Col md="6">
                            <div className="mb-3">
                                <Label htmlFor="paymentCourseId">Course</Label>
                                <Select
                                    name={"paymentCourseId"}
                                    id={"paymentCourseId"}
                                    options={courses}
                                    value={courses ? courses.filter(x => x.value == courseId)[0] : ''}
                                    onChange={(e) => { setCourseId(e?.value);}}
                                    isClearable = {true}
                                />
                            </div>
                        </Col>
                        <Col md="12">
                            <div className="mb-3">
                                <Label htmlFor="serviceDescription">Service Description</Label>
                                <AvField
                                name="serviceDescription"
                                defaultValue={updateData['serviceDescription']}
                                placeholder="Description"
                                type="textarea"
                                validate={{ required: { value: false } }}
                                className="form-control"
                                id="serviceDescription"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Col style={{textAlign: 'right'}}>
                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                    </Col>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>
        }
    </Row>
    )
}

export default Model
