import React, {useState, useEffect} from "react"
import { Card, CardBody, Table, CardTitle, Col, Button,Label,Row } from "reactstrap"
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import * as SHelper from "../../helpers/session_helper"
import { toast, Slide } from 'react-toastify/dist/react-toastify';
import Select from "react-select"

const EnrollByParticipant = ({academicProfileId}) => {
    const [paymentPolicy, setPaymentPolicy] = useState(false);
    const [transactionHistory, setTransactionsHistory] = useState(false);
    const [serviceDD, setServiceDD] = useState([]);
    const [courseMainDD, setCourseMainDD] = useState([]);
    const [serviceId, setServiceId] = useState(false);
    const [courseId, setCourseId] = useState(false);

    useEffect(()=>{
        Axios.get(`/service/dropdown`)
        .then(response => {
            if(response.status === 200){
                setServiceDD(response.data.data);
            }
        })
        .catch((e) => {
            toast.error(e, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        })

        Axios.get(`/course/dropdown`)
        .then(response => {
            if(response.status === 200){
              setCourseMainDD(response.data.data);
            }
        })
        .catch((e) => {
            toast.error(e, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        })
    },[])

    useEffect(()=>{
      if(serviceId){
        fetchServicePaymentPolicy(serviceId.value);
      }
    },[serviceId])

    const fetchServicePaymentPolicy = (sId) => {
        Axios.get(`/service/paymentPolicy/${academicProfileId}&${sId}`)
        .then(response => {
            if(response.status === 200){
                if(response.data.paymentPolicy) {
                    setPaymentPolicy(response.data.paymentPolicy);
                    setTransactionsHistory(false);
                }
                if(response.data.transactions) {
                    setTransactionsHistory(response.data.transactions);
                    setPaymentPolicy(false);
                }
            }
        })
        .catch((e) => {
            toast.error(e, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        })
    }

    const openPaymentWindow = (paymentUrl) => {
        window.location.href = paymentUrl;
    };

    async function initiatePayment(applicationFee, installmentNo, initialPayment =false){
        window.sLoader();

        const user=SHelper.getUser();
        let isParticipant = false;

        if(user && user.role_code=='444') {
            isParticipant = true;
        }

        const successUrl = JSON.stringify(
            {
                academicProfileId:academicProfileId,
                activeTab:6,
                applicationFee:applicationFee,
                installmentNo:installmentNo,
                serviceId:serviceId.value,
            }
        )

        const jsonData = {
            academicProfileId: academicProfileId,
            serviceId: serviceId.value,
            applicationFee : applicationFee,
            installmentFee: !applicationFee,
            installmentNo: installmentNo,
            successUrlParams: encodeURIComponent(CHelper.encryptData(successUrl)),
            value_a:`academicProfileId_${academicProfileId}`,
            value_b:`serviceId_${serviceId.value}`,
            value_c:`applicationFee_${applicationFee?1:0}`,
            value_d:`installmentNo_${installmentNo}`,
            isParticipant: isParticipant??false,
            initialPayment: initialPayment,
            redirectUrl : `profile?servicePage=true`
        }

        await Axios.post(`/open/sslcommerz/init`, jsonData)
            .then(async (response) => {
                if (response.data.status === 200) {
                    openPaymentWindow(decodeURIComponent(response.data.message));
                } else {
                    window.hLoader();
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                }
            })
            .catch((e) => {
                window.hLoader();
                toast.error(e, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            })
    }

    const TransactionStatus = ({transactionStatus, initialPayment}) =>{
        return (
            <>
                {transactionStatus==1 && <span className="badge badge-soft-secondary font-size-12">{initialPayment ? "-" : "UnPaid"}</span>}
                {transactionStatus==2 && <span className="badge badge-soft-warning font-size-12">Processing</span>}
                {transactionStatus==3 && <span className="badge badge-soft-info font-size-12">Checking</span>}
                {transactionStatus==4 && <span className="badge badge-soft-success font-size-12">Confirmed</span>}
                {transactionStatus==5 && <span className="badge badge-soft-danger font-size-12">Denied From SSL</span>}
                {transactionStatus==6 && <span className="badge badge-soft-danger font-size-12">Failed</span>}
                {transactionStatus==7 && <span className="badge badge-soft-danger font-size-12">Cancelled</span>}
            </>
        )
    }

    const TransactionButton = ({transactionDetail,transactionNo, initialPayment}) =>{
        return (
            <>
                {transactionDetail.transactionStatus==1 && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo,initialPayment)} className="btn btn-info btn-sm">Pay now</Button>}
                {transactionDetail.transactionStatus==2 && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo,initialPayment)} className="btn btn-warning btn-sm">Pay Again</Button>}
                {transactionDetail.transactionStatus==5 && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo, initialPayment)} className="btn btn-info btn-sm">Pay Again</Button>}
                {transactionDetail.transactionStatus==6 && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo, initialPayment)} className="btn btn-info btn-sm">Pay now</Button>}
                {transactionDetail.transactionStatus==7 && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo, initialPayment)} className="btn btn-info btn-sm">Pay now</Button>}
            </>
            
        )
    }

    return (
        <>
          <Card style={{padding:"0"}}>
            <CardBody style={{padding:"0"}}>
              <Row>
                <Col md={'12'}>
                  <Label htmlFor="serviceId">Service <span className="requiredSign">*</span></Label>
                  <Select
                      name={"serviceId"}
                      id={"serviceId"}
                      isClearable={true}
                      options={serviceDD}
                      value={serviceDD.filter(x => x.value == serviceId?.value)}
                      onChange={(e) => { setServiceId(e); }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>

          {paymentPolicy && paymentPolicy.length>0 && 
            <Card>
                <CardBody style={{paddingLeft:"0",paddingRight:"0"}}>
                    <div className="table-responsive">
                        <Table className="table-centered">
                            <thead style={{ backgroundColor: "#3b5de7", color: "white", textAlign: "left" }}>
                                <tr>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Payment Type
                                    </th>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Transaction Date
                                    </th>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Amount
                                    </th>
                                    <th
                                    scope="col"
                                    colSpan="2"
                                    style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}
                                    >
                                    Payment Status
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {paymentPolicy.map((item,i)=>{
                                    return ( 
                                        <tr key={i}>
                                            <td>Service Fee</td>
                                            <td>{item.transactionSSLCommerzConfirmedDate??"-"}</td>
                                            <td>Tk. {new Intl.NumberFormat('en-US').format(item.transactionInstallmentAmount)}</td>
                                            <td><TransactionStatus transactionStatus={item.transactionStatus}/></td>
                                            <td style={{textAlign:"center"}}><TransactionButton transactionDetail={item} transactionNo={i} initialPayment={true}/></td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>}

            {transactionHistory && transactionHistory.length>0 &&
            <Card>
                <CardBody style={{paddingLeft:"0",paddingRight:"0"}}>
                    <div className="table-responsive">
                        <Table className="table-centered">
                        <thead style={{ backgroundColor: "#3b5de7", color: "white", textAlign: "left" , opacity:0.9}}>
                                <tr>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Payment Type
                                    </th>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Transaction Date
                                    </th>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Amount
                                    </th>
                                    <th
                                    scope="col"
                                    colSpan="2"
                                    style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}
                                    >
                                    Payment Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {transactionHistory.length>1 ?  */}
                                {transactionHistory.map((item,i)=>{
                                    return ( 
                                        <tr key={i}>
                                            <td>Service Fee</td>
                                            <td>{item.transactionSSLCommerzConfirmedDate??"-"}</td>
                                            <td>Tk. {new Intl.NumberFormat('en-US').format(item.transactionInstallmentAmount)}</td>
                                            <td><TransactionStatus transactionStatus={item.transactionStatus}/></td>
                                            <td style={{textAlign:"center"}}><TransactionButton transactionDetail={item} transactionNo={i}/></td>
                                        </tr>)
                                    })}
                                    {/* :
                                    <tr>
                                        <td>Course Fee</td>
                                        <td>{transactionHistory[0].perInstallmentAmount} Taka</td>
                                        <td><span className="badge badge-soft-danger font-size-12">Unpaid</span></td>
                                        <td><Button onClick={()=>initiatePayment(false, 0)} className="btn btn-primary btn-sm">Pay now</Button></td>
                                    </tr>} */}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>}
        </>
    )
}

export default EnrollByParticipant