import { useHistory } from "react-router-dom"
import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Modal, Input,
  Button as RButton,
  Label 
} from "reactstrap"
  import * as CHelper from "../../helpers/custom_helper"
  import { AvForm, AvField } from "availity-reactstrap-validation"
  import { toast,Slide } from 'react-toastify/dist/react-toastify';
  import MaterialReactTable from 'material-react-table';
  import { Download } from '@mui/icons-material';  
  import { ExportToCsv } from 'export-to-csv';
  import {
    Box,
    Button,
    IconButton,
    Typography,
    Tooltip,
  } from '@mui/material';
  import { Edit,Mail } from '@mui/icons-material';
//Import Breadcrumb
import Select from "react-select"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import TableLoader from "../../components/Common/TableLoader"
import Axios from "../../helpers/axios_helper"
import * as sessionHelper from '../../helpers/session_helper';
import * as Config from '../../config';

const DatatableTables = () => {
  const [data, setData] = useState([]);
  const [sessionUser, setSessionUser] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const selectedItem = useRef(0);
  const selectedItemDetails = useRef([]);
  const [modal_center, setmodal_center] = useState(false)

  const [paymentCoUser, setServiceCoUser] = useState(false);
  const [userHasEditPermission, setUserHasEditPermission] = useState(false);
  const [statusDD, setStatusDD] = useState([]);
  const [statusId, setStatusId] = useState([]);
  const [serviceDD, setServiceDD] = useState([]);
  const [serviceId, setServiceId] = useState(false);

  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState({
    academicEmail: 'contains',
    participantId: 'contains',
    academicContactNo: 'contains'
  });

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const tableInstanceRef = useRef(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });
  
  //#region Datatable

  const getStatusLabel = (statusValue,statusLabel='',badgeClass='') =>{
    switch (statusValue) {
      case '1':
        statusLabel = 'Not Initiated';
        badgeClass = 'badge badge-soft-secondary font-size-12';
        break;
      case '2':
        statusLabel = 'Processing';
        badgeClass = 'badge badge-soft-warning font-size-12';
        break;
      case '3':
        statusLabel = 'IPN Confirmed';
        badgeClass = 'badge badge-soft-info font-size-12';
        break;
      case '4':
        statusLabel = 'Validated';
        badgeClass = 'badge badge-soft-success font-size-12';
        break;
      case '5':
        statusLabel = 'Validation Failed';
        badgeClass = 'badge badge-soft-danger font-size-12';
        break;
      case '6':
        statusLabel = 'Failed';
        badgeClass = 'badge badge-soft-danger font-size-12';
        break;
      case '7':
        statusLabel = 'Canceled';
        badgeClass = 'badge badge-soft-danger font-size-12';
        break;
      default:
        statusLabel = 'N/A';
        badgeClass = 'badge badge-soft-dark font-size-12';
    }

    return {statusLabel,badgeClass}
  }

  const columns = useMemo(
    () => [
      {
        size:50,
        header: "#SL",
        accessorKey: 'sl',
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        size:50,
        accessorKey: 'participantId',
        header: "Participant ID",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        size:50,
        accessorKey: 'learnerId',
        header: "Learner ID",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()??'-'}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        accessorKey: 'academicFullName',
        header: "Full name",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'academicContactNo',
        header: "Contact",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'academicEmail',
        header: "Email",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      
      {
        header: "Service",
        accessorKey: 'enrolledCourseOrServices.service.serviceName',
        Cell: ({ cell, row }) => (
          <Typography variant='h7' key={row.index}>{row.original['enrolledCourseOrServices.service.serviceName']??'-'}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        header: "Service Code",
        accessorKey: 'enrolledCourseOrServices.service.serviceCode',
        Cell: ({ cell, row }) => (
          <Typography variant='h7' key={row.index}>{row.original['enrolledCourseOrServices.service.serviceCode']??'-'}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        header: "Course",
        accessorKey: 'enrolledCourseOrServices.service.course.courseName',
        Cell: ({ cell, row }) => (
          <Typography variant='h7' key={row.index}>{row.original['enrolledCourseOrServices.service.course.courseName']??'-'}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        header: "Transaction Amount",
        accessorKey: 'enrolledCourseOrServices.transactions.transactionInstallmentAmount',
        Cell: ({ cell, row }) => (
          <Typography variant='h7' key={row.index}>{row.original['enrolledCourseOrServices.transactions.transactionInstallmentAmount']??"-" }</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      
      {
        header: "Transaction Date",
        accessorKey: 'enrolledCourseOrServices.transactions.transactionSSLCommerzConfirmedDate',
        Cell: ({ cell, row }) => (
          <Typography variant='h7' key={row.index}>{row.original['enrolledCourseOrServices.transactions.transactionSSLCommerzConfirmedDate']??"-" }</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        header: "Confirmed Date",
        accessorKey: 'enrolledCourseOrServices.transactions.transactionDate',
        Cell: ({ cell, row }) => (
          <Typography variant='h7' key={row.index}>{row.original['enrolledCourseOrServices.transactions.transactionDate']??"-" }</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        header: "Transaction Status",
        accessorKey: 'enrolledCourseOrServices.transactions.transactionStatus',
        Cell: ({ cell, row }) => {
          const status= getStatusLabel(row.original['enrolledCourseOrServices.transactions.transactionStatus']);
          return <span className={status.badgeClass}>{status.statusLabel}</span>;
        },
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      }
    ],
    [], 
  );

  const findFilterCondition = (name) => {
    var condition = '';
    if (columnFilterFns[name]) {
      condition = columnFilterFns[name];
    }
    return condition;
  }

  const fetchDataFromAPI = async (reset = false) => {
    if(!serviceId){
      toast.error("Please select service !", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
        transition: Slide,
      });
      return;
    }

    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    let filterQuery = '';
    let sorterQuery = '';

    if (!reset) {
      if (columnFilters.length > 0) {
        columnFilters.forEach(element => {
          filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${element.value}`;
        });
      }
      if (sorting.length > 0) {
        sorting.forEach(element => {
          sorterQuery += `&sortby=${element.id}&orderby=${element.desc ? 'desc' : 'asc'}`;
        });
      }
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: 5
      });
      tableInstanceRef.current.resetRowSelection();
    }

    let statusParam = statusId;
   
    if(statusParam.includes('-1')){
      statusParam = -1
    }
    else{
      let selected = statusParam && statusParam.length>0 ? statusParam.map(item => item.value) : [-1];
      statusParam = selected.join('_')
    }

    const url = `/academicProfile/serviceHistoryByCourse/${serviceId}&${statusParam}?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
    await Axios.get(url)
      .then(function (response) {
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        if (response && response.data?.data) {
          setData(response.data?.data);
          setRowCount(response.data.recordsTotal);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
  };

  const sendMail = async (event, errors, values) => {
    window.sLoader();
    if (errors.length > 0) {
        errors.forEach(element => {
            toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        });
    }
    else {
        if (selectedItem.current) {
            const jsonData = {
                academicEmail: values.to,
                mailSubject: values.subject,
                mailBody: values.body
            }

            await Axios.post(`/academicProfile/sendDynamicMail`, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        toast.success('Mail send successfully!', {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                    else {
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    toast.error(e.message, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })
                handleCallback();
        }
    }
    
    window.hLoader();
  }

  useEffect(() => {
    if(!serviceId){
      return;
    }
    else
      fetchDataFromAPI();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting
  ]);

  useEffect(() => {
    if(!serviceId){
      return;
    }
    else
      fetchDataFromAPI(true);
  }, [
    activeTab
  ]);

  useEffect(async () => {
    if(sessionHelper.userHasEditPermission()){
      setSessionUser(sessionHelper.getUser());
      setUserHasEditPermission(true);
      await Axios.get('/service/dropdown')
        .then(function (response) {
          if (response && response.data.status==200) {
            let dd = response.data.data;
            let allCourse = {label:"All Services", value:"-1"};
            setServiceDD([allCourse,...dd]);
          }
          else {
            toast.error(response.statusText ?? "Data fetching failed !", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
              transition: Slide,
            });
          }
        })
        .catch(function (error) {
          // navigate('/');
        });
    }
    else{
      if(sessionHelper.userIsServiceCO()){
        setServiceCoUser(true);
      }
      await Axios.get('/user/access/dropdowns')
      .then(function (response) {
        if (response && response.data.status==200) {
          setServiceDD(response.data.serviceDD);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
    }

    setStatusDD([
      {label:"Not Initiated",value:1},
      {label:"Processing",value:2},
      {label:"SSL/IPN Confirmed",value:3},
      {label:"Validated",value:4},
      {label:"Validation Failed",value:5},
      {label:"Failed",value:6},
      {label:"Canceled",value:7}
    ]);

  }, []);

  //#endregion

  //#region Modal

  const handleCallback = (response) =>{
    selectedItem.current=0;
    setmodal_center(false);
    selectedItemDetails.current=[];
  }

  const csvOptions = {
        filename:'Service History',
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        headers: columns.map((c) => c.header),
      };
      
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    const csvData = rows.map((row) => {
      const processedRow = {};
      columns.forEach((column) => {
          // Use column.header as the key and row.original[column.accessorKey] as the value
          // processedRow[column.header] = column.accessorKey
          //     ? row.original[column.accessorKey]
          //     : null;
          if(column.accessorKey=='enrolledCourseOrServices.transactions.transactionStatus'){
            processedRow[column.header] = column.accessorKey
              ? getStatusLabel(row.original[column.accessorKey])?.statusLabel
              : '-';
          }
          else{
            processedRow[column.header] = column.accessorKey
              ? row.original[column.accessorKey]
              : '-';
          }
      });
      return processedRow;
  });
    csvExporter.generateCsv(csvData);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPagination({
      ...pagination,
      pageIndex: 0, // Reset to the first page when pageSize changes
      pageSize: newPageSize==-1?9999999:newPageSize,
    });
  };
  //#endregion

  return (
    <>
      <div className="page-content">

        <Breadcrumbs title="Service" breadcrumbItem="History" />

        <Row>
          <Col className="col-12">
            <Card>
              <CardBody>
                <AvForm className="needs-validation" onSubmit={()=>{}}>
                  <Row>
                    <Col md={6} style={{zIndex:"93"}}>
                      <Row className="mb-3">
                        <label className="col-md-4 col-form-label">Service</label>
                        <div className="col-md-8">
                          <Select
                            name={"serviceId"}
                            value={serviceDD ? serviceDD.find(x => x.value == serviceId) : null}
                            id="serviceId"
                            options={serviceDD}
                            onChange={(e)=>{setServiceId(e.value);}}>
                          </Select>
                        </div>
                      </Row>
                    </Col>
                    <Col md={6} style={{zIndex:"90"}}>
                      <Row className="mb-3">
                        <label className="col-md-4 col-form-label">Status</label>
                        <div className="col-md-8">
                        <Select
                            name={"paymentTypeId"}
                            id="paymentTypeId"
                            options={statusDD}
                            isMulti={true}
                            value={statusId}
                            onChange={(e)=>{setStatusId(e)}}>

                          </Select>
                        </div>
                      </Row>
                    </Col>
                    <Col md={12} style={{textAlign:"right",zIndex:"89"}}>
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light"
                      onClick={()=>{fetchDataFromAPI()}}
                    >
                      <i className="mdi mdi-database-search font-size-16 align-middle me-2"></i>{" "}
                        Search
                      </button>{" "}
                    </Col>
                  </Row>
                </AvForm>
                <br/>
                {/* <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === 1,
                      })}
                      onClick={() => {
                        setActiveTab(1)
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="fas fa-home"></i></span>
                      <span className="d-none d-sm-block">Active</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === 0,
                      })}
                      onClick={() => {
                        setActiveTab(0)
                      }}
                    >
                      <span className="d-block d-sm-none"><i className="far fa-user"></i></span>
                      <span className="d-none d-sm-block">In-Active</span>
                    </NavLink>
                  </NavItem>
                </Nav> */}

                {true ?
                  <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableColumnActions={false}
                    enableGlobalFilter={false}
                    enableColumnFilterModes
                    initialState={{
                      showColumnFilters: false
                    }}
                    manualFiltering
                    manualPagination
                    manualSorting
                    onColumnFilterFnsChange={setColumnFilterFns}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    tableInstanceRef={tableInstanceRef}
                    rowCount={rowCount}
                    enableEditing
                    state={{
                      columnFilters,
                      columnFilterFns,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                    }}
                    positionActionsColumn="last"
                    displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Action',
                      },
                    }}
                    renderTopToolbarCustomActions={({ table }) => (
                      <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                      >
                        <Button
                          disabled={table.getRowModel().rows.length === 0}
                          //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                          onClick={() => handleExportRows(table.getRowModel().rows)}
                          startIcon={<Download />}
                          variant="contained"
                        >
                          Page Rows
                        </Button>
                        <Button
                          disabled={
                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                          }
                          //only export selected rows
                          onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                          startIcon={<Download />}
                          variant="contained"
                        >
                          Selected Rows
                        </Button>
                      </Box>
                    )}
                    enableRowSelection={true}
                    muiTablePaginationProps={{
                      rowsPerPageOptions: [5, 10, 25, 50, 100, { label: 'All', value: -1 }],
                      onRowsPerPageChange: (event) => {
                        handlePageSizeChange(Number(event.target.value));
                      },
                      showFirstButton: true,
                      showLastButton: true,
                    }}
                    renderRowActions={({ row, table }) => (
                      <Box sx={{ display: 'flex' }}>
                        <Tooltip arrow placement="left" title="Send Mail">
                          <IconButton onClick={() => {selectedItem.current=row.original.id; selectedItemDetails.current=row.original;  setmodal_center(true)}}>
                            <Mail />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                  />
                  :
                  <TableLoader />
                }
              </CardBody>
            </Card>
          </Col>
          <Modal
            size="lg"
            isOpen={modal_center}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Service Mail</h5>
              <button
                type="button"
                onClick={handleCallback}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
            <Col xl="12">
                <Card>
                    <CardBody>
                        <AvForm className="needs-validation" onSubmit={sendMail}>
                        <Row>
                            <Col md="6">
                                <div className="mb-3">
                                    <Label htmlFor="name">Name</Label>
                                    <AvField
                                        name="name"
                                        type="text"
                                        readOnly={true}
                                        value={selectedItemDetails.current.academicFullName}
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        id="name"
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-3">
                                    <Label htmlFor="to">To</Label>
                                    <AvField
                                        name="to"
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        value={selectedItemDetails.current.academicEmail}
                                        validate={{ required: { value: true } }}
                                        id="to"
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-3">
                                    <Label htmlFor="to">Service</Label>
                                    <AvField
                                        name="service"
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        value={selectedItemDetails.current['enrolledCourseOrServices.service.serviceName']}
                                        validate={{ required: { value: true } }}
                                        id="service"
                                    />
                                </div>
                            </Col>
                            <Col md="6">
                                <div className="mb-3">
                                    <Label htmlFor="to">Course</Label>
                                    <AvField
                                        name="course"
                                        type="text"
                                        className="form-control"
                                        readOnly={true}
                                        value={selectedItemDetails.current['enrolledCourseOrServices.service.course.courseName']??'-'}
                                        validate={{ required: { value: true } }}
                                        id="course"
                                    />
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label htmlFor="subject">Mail Subject</Label>
                                    <AvField
                                        name="subject"
                                        placeholder="Subject"
                                        type="text"
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        errorMessage="Please provide a mail subject"
                                        id="subject"
                                    />
                                </div>
                            </Col>
                            <Col md="12">
                                <div className="mb-3">
                                    <Label htmlFor="body">Mail Body</Label>
                                    <AvField
                                        name="body"
                                        placeholder="Body"
                                        type="textarea"
                                        rows={6}
                                        className="form-control"
                                        defaultValue = {`Dear ${selectedItemDetails.current.academicFullName},\n\n\n\nBest Regards,\n${sessionUser.full_name}\n${sessionUser.email_org??''} `}
                                        validate={{ required: { value: true } }}
                                        errorMessage="Please provide a mail body"
                                        id="body"
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Col style={{textAlign: 'right'}}>
                          <RButton color="primary" type="submit">
                            <i className="mdi mdi-email-send-outline font-size-14 align-middle me-2"></i>{" "}
                            Send Mail
                          </RButton>
                        </Col>
                        </AvForm>
                    </CardBody>
                </Card>
            </Col>
            </div>
          </Modal>

          {/* <CustomModal modelShow={update_status_modal_center} handleCallback={modalCallbackStatus}/> */}
          {/* <CustomModal modelShow={reset_modal_center} handleCallback={modalCallbackReset} bodyMsg={"New password will sent to the user through user email address."}/> */}
        </Row>
      </div>

    </>
  )
}

export default DatatableTables
