import React, { useEffect } from "react"
import { Container, Row, Col, Card, CardBody } from "reactstrap"

//Import Images
import errorImage from "../../assets/images/success-img.jpg"


const PaymentChecking = () => {
  useEffect(() => {
    window.close();
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">

                <CardBody>
                  <div className="text-center p-3">

                    <div className="img">
                      <img src={errorImage} className="img-fluid" alt="Loader" style={{maxHeight:"250px"}}/>
                    </div>

                    {/* <h2 className="error-page mt-5"><span>OK</span></h2> */}
                    <h4 className="mb-4 mt-5">Congratulations</h4>
                    <p className="mb-4 w-75 mx-auto">Payment Successfull.</p>
                  </div>

                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PaymentChecking
