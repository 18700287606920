import React, { useState, useEffect, useRef } from "react"


import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
import './model.scss'
const moment = require('moment');

const Model = (props) => {
    const history = useHistory()
    const [roleId, setRoleId] = useState(0)
    const [updateData, setUpdateData] = useState(false)
    const [file, setFile] = useState([]);
    const [files, setFiles] = useState([]);

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        if (errors.length > 0) {
            errors.forEach(element => {
                toast.error(`${CHelper.separateCamelCaseString(element)} is required !`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            });
        }
        else if (!values.options) {
            toast.error(`Feature image is required !`, {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        }
        // else if (!props.id && files.length == 0) {
        //     toast.error(`Feature image is required !`, {
        //         position: toast.POSITION.TOP_RIGHT,
        //         autoClose: 3000,
        //     });
        // }
        else {
            if (props.id && props.id == updateData.id) {
                const jsonData = {
                    setName: values.setName,
                    questionText: values.questionText,
                    options: values.options.split(','),
                    correctAnswer: values.correctAnswer,
                    isActive: updateData.isActive==1
                }

                await Axios.patch(`/question-bank/id/${updateData.id}`, jsonData)
                    .then(async (response) => {
                        if (response.data.status === 200) {
                                toast.success('User Details updated successfully!', {
                                    position: toast.POSITION.TOP_RIGHT,
                                    autoClose: 3000,
                                });
                                props.handleCallback(response.data)
                        }
                        else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }
            else {
                const jsonData = {
                    setName: values.setName,
                    questionText: values.questionText,
                    options: values.options.split(','),
                    correctAnswer: values.correctAnswer,
                    isActive: true
                }

                // inputString = inputString.replace(/(\w+)/g, '"$1"');

                await Axios.post("/question-bank", jsonData)
                    .then(async (response) => {
                        if (response.data.status === 201) {

                            toast.success('Subjects / Modules created successfully!', {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                            setTimeout(() => {
                                history.push("/question-bank-list");
                            }, 1000);

                        } else {
                            toast.error(response.data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                                autoClose: 3000,
                            });
                        }
                    })
                    .catch((e) => {
                        toast.error(e, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    })
            }

        }
        
        window.hLoader();
    }

    useEffect(async () => {
        if (props.id && props.id > 0) {
            await Axios.get(`/question-bank/id/${props.id}`)
                .then((response) => {
                    if (response.data.status === 200) {
                        try {
                            var inputString = response.data.data.options;
                            inputString =inputString.replace(/\\/g, '');
                            if (inputString.startsWith('"') && inputString.endsWith('"')) {
                                inputString = inputString.slice(1, -1);
                            }
                            inputString=JSON.parse(inputString);
                            inputString.forEach(element => {
                                element=element.replace(/\\/g, '"');
                            });
                            
                            inputString=inputString.join(',');
                            response.data.data.options=inputString;
                        } catch (error) {
                            console.log(error)
                        }
                        
                        setUpdateData(response.data.data);
                    }
                })
        }

    }, [props.id]);

    return (
        <Row>
        {(!props.id || props.id===0 || (props.id>0 && updateData) ) &&
        <Col xl="12">
            <Card>
                <CardBody>
                    <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    <Row>
                    <Col md="6">
                        <div className="mb-3">
                            <Label htmlFor="setName">Set Name</Label>
                            <AvField
                                name="setName"
                                defaultValue={updateData['setName']}
                                placeholder="Set Name"
                                type="text"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                errorMessage="Please provide a set name."
                                id="setName"
                            />
                        </div>
                        </Col>
                        <Col md="6">
                        <div className="mb-3">
                            <Label htmlFor="point">Point</Label>
                            <AvField
                                name="point"
                                defaultValue={updateData['point']}
                                placeholder="Point"
                                type="number"
                                validate={{ required: { value: false } }}
                                className="form-control"
                                id="point"
                            />
                        </div>
                        </Col>
                        <Col md="12">
                        <div className="mb-3">
                            <Label htmlFor="questionText">Question Text</Label>
                            <AvField
                                name="questionText"
                                defaultValue={updateData['questionText']}
                                placeholder="QuestionText"
                                type="text"
                                validate={{ required: { value: false } }}
                                className="form-control"
                                id="questionText"
                            />
                        </div>
                        </Col>
                        <Col md="12">
                        <div className="mb-3">
                            <Label htmlFor="questionText">Options</Label>
                            <AvField
                                name="options"
                                defaultValue={updateData['options']}
                                placeholder="Options by comma (,) separated"
                                type="text"
                                validate={{ required: { value: false } }}
                                className="form-control"
                                id="options"
                            />
                        </div>
                        </Col>
                        <Col md="12">
                        <div className="mb-3">
                            <Label htmlFor="correctAnswer">Correct Answer</Label>
                            <AvField
                                name="correctAnswer"
                                defaultValue={updateData['correctAnswer']}
                                placeholder="Correct Answer"
                                type="text"
                                validate={{ required: { value: true } }}
                                className="form-control"
                                id="correctAnswer"
                            />
                        </div>
                        </Col>
                    </Row>
                    <Col style={{textAlign: 'right'}}>
                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                    </Col>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>
        }
    </Row>
    )
}

export default Model
