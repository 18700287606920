import React, {useState, useEffect} from "react"
import { Card, CardBody, Table, CardTitle, Row, Col, Button } from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { toast,Slide } from 'react-toastify/dist/react-toastify';
import Axios from "../../helpers/axios_helper"

const PaymentPolicy = ({transactionDetails,handleCallback}) => {
    const [transactionHistory, setTransactionsHistory] = useState(false);
    const [transactionInstallmentGracePeriod, setTransactionInstallmentGracePeriod] = useState(false);
    const [mailNote, setMailNote] = useState('');

    useEffect(()=>{
        if(transactionDetails && transactionDetails!=0){
            setTransactionsHistory(transactionDetails);
            setTransactionInstallmentGracePeriod(transactionDetails['enrolledCourseOrServices.transactions.transactionInstallmentGracePeriod'])
        }
        else{
            setTransactionsHistory(false);
        }
        return;
    },[transactionDetails])

    const extendGracePeriod = async () =>{
      const jsonData ={
        transactionInstallmentGracePeriod:transactionInstallmentGracePeriod
      } 
      await Axios.patch(`/academicProfile/transactionGracePeriodUpdate/${transactionHistory['enrolledCourseOrServices.transactions.id']}`, jsonData)
            .then(async (response) => {
                if (response.data.status === 201) {
                  toast.success("Update Successfull !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    transition: Slide,
                  });
                } else {
                  toast.error(response.data?.message ?? "Data Update Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    transition: Slide,
                  });
                }
            })
            .catch((e) => {
              toast.error(e.data?.message ?? "Data Update Failed !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                transition: Slide,
              });
              if(handleCallback){
                handleCallback(false);
              }
            })
    }

    const sendGracePeriodExtensionMail = async () =>{
      const jsonData ={
        mailNote: mailNote,
        academicFullName: transactionDetails.academicFullName,
        academicEmail: transactionDetails.academicEmail,
        courseId: transactionDetails['enrolledCourseOrServices.courseId'],
        courseName: transactionDetails['enrolledCourseOrServices.course.courseName'],
        courseCode: transactionDetails['enrolledCourseOrServices.course.courseCode'],
        transactiontype: transactionDetails['enrolledCourseOrServices.transactions.transactionType'],
        installmentNo: transactionDetails['enrolledCourseOrServices.transactions.transactionInstallmentNo']
      } 
      await Axios.post(`/academicProfile/transactionGracePeriodUpdate/sendMail`, jsonData)
            .then(async (response) => {
                if (response.data.status === 200) {
                  toast.success("Mail Send Successfull !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    transition: Slide,
                  });
                  if(handleCallback){
                    handleCallback(true);
                  }
                } else {
                  toast.error(response.data?.message ?? "Mail Send Failed !", {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                    transition: Slide,
                  });
                }
            })
            .catch((e) => {
              toast.error(e.data?.message ?? "Mail Send Failed !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
                transition: Slide,
              });
              if(handleCallback){
                handleCallback(false);
              }
            })
    }

    const TransactionStatus = ({transactionStatus}) =>{
        return (
            <>
                {transactionStatus==1 && <span className="badge badge-soft-secondary font-size-14">Unpaid</span>}
                {transactionStatus==2 && <span className="badge badge-soft-warning font-size-14">Processing</span>}
                {transactionStatus==3 && <span className="badge badge-soft-info font-size-14">Checking</span>}
                {transactionStatus==4 && <span className="badge badge-soft-success font-size-14">Confirmed</span>}
                {transactionStatus==5 && <span className="badge badge-soft-danger font-size-14">Denied From SSL</span>}
                {transactionStatus==6 && <span className="badge badge-soft-danger font-size-14">Failed</span>}
                {transactionStatus==7 && <span className="badge badge-soft-danger font-size-14">Cancelled</span>}
            </>
            
        )
    }

    return (
        <Row style={{padding:"0 15px"}}>
            {transactionHistory &&
            <Card>
                <CardBody>
            <CardTitle className="h4 mb-5">
                {transactionHistory['enrolledCourseOrServices.transactions.transactionType']=='1'?"Application Fee":`Installment No ${transactionHistory['enrolledCourseOrServices.transactions.transactionInstallmentNo']}`}
                &nbsp;&nbsp;(<TransactionStatus transactionStatus={transactionHistory['enrolledCourseOrServices.transactions.transactionStatus']}/>)

            </CardTitle>
            <ul className="list-unstyled activity-wid">
              <li className="activity-list">
                <div className="activity-icon avatar-xs">
                  <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                    <i className="mdi mdi-calendar-edit"></i>
                  </span>
                </div>
                <div className="d-flex align-items-start">
                  <div className="me-3">
                    <h5 className="font-size-14">Payment Due Date <i
                      className="mdi mdi-arrow-right text-primary align-middle ms-2"></i>
                    </h5>
                  </div>
                  <div className="flex-1">
                    <div>
                    {transactionHistory['enrolledCourseOrServices.transactions.transactionInstallmentStartDate']}
                    </div>
                  </div>
                </div>
              </li>
              <li className="activity-list">
                <div className="activity-icon avatar-xs">
                  <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                    <i className="mdi mdi-calendar-edit"></i>
                  </span>
                </div>
               
                 <div className="d-flex align-items-start">
                  <div className="me-3">
                    <h5 className="font-size-14">Grace Period <i
                      className="mdi mdi-arrow-right text-primary align-middle ms-2"></i>
                    </h5>
                  </div>
                  <div className="flex-1">
                    <div style={{color:transactionHistory['enrolledCourseOrServices.transactions.transactionInstallmentGracePeriodOver']?'red':''}}>
                    {transactionHistory['enrolledCourseOrServices.transactions.transactionInstallmentGracePeriod']}
                    {transactionHistory['enrolledCourseOrServices.transactions.transactionInstallmentGracePeriodOver']&&" (Overdue)"}
                                                </div>
                  </div>
                </div>
              </li>
              <li className="activity-list">
              <div className="activity-icon avatar-xs">
                <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                  <i className="mdi mdi-calendar-edit"></i>
                </span>
              </div>
               <div className="d-flex align-items-start">
                <div className="me-3">
                  <h5 className="font-size-14">Transactional Attempt taken<i
                    className="mdi mdi-arrow-right text-primary align-middle ms-2"></i>
                  </h5>
                </div>
                <div className="flex-1">
                  <div>
                  {transactionHistory['enrolledCourseOrServices.transactions.transactionDate']??'N/A'}
                  </div>
                </div>
              </div>
            </li>
            <li className="activity-list">
                <div className="activity-icon avatar-xs">
                  <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                    <i className="mdi mdi-calendar-edit"></i>
                  </span>
                </div>
                 <div className="d-flex align-items-start">
                  <div className="me-3">
                    <h5 className="font-size-14">Payment Confirmation <i
                      className="mdi mdi-arrow-right text-primary align-middle ms-2"></i>
                    </h5>
                  </div>
                  <div className="flex-1">
                    <div>
                    {transactionHistory['enrolledCourseOrServices.transactions.transactionSSLCommerzConfirmedDate']??"N/A"}
                                                </div>
                  </div>
                </div>
              </li>
              <li className="activity-list" style={{display:"none"}}>
                <div className="activity-icon avatar-xs">
                  <span className="avatar-title bg-soft-primary text-primary rounded-circle">
                    <i className="mdi mdi-calendar-edit"></i>
                  </span>
                </div>
                 <div className="d-flex align-items-start">
                  <div className="me-3">
                    <h5 className="font-size-14">SSL Confirmed <i
                      className="mdi mdi-arrow-right text-primary align-middle ms-2"></i>
                    </h5>
                  </div>
                  <div className="flex-1">
                    <div>
                    {transactionHistory['enrolledCourseOrServices.transactions.transactionSSLCommerzConfirmedDate']??"N/A"}
                                                </div>
                  </div>
                </div>
              </li>
            </ul>
            {transactionInstallmentGracePeriod && transactionHistory['enrolledCourseOrServices.transactions.transactionInstallmentGracePeriodOver'] &&
            <>
            <br/>
            <Row>
                  <Col md={8}>
                    <Row className="mb-3">
                      <label className="col-md-4 col-form-label">Extend Grace Period</label>
                      <div className="col-md-8">
                      <Flatpickr
                          name={"trainingEndDate"}
                          id={"trainingEndDate"}
                          className="form-control d-block"
                          disabled={false}
                          options={{
                              altInput: true,
                              enableTime: true,
                              time_24hr: false, // For 12-hour clock (AM/PM)
                              altFormat: "F j, Y h:i K", // Display format with time
                              dateFormat: "Y-m-d H:i", // Submission format with time
                              defaultDate: new Date(transactionHistory['enrolledCourseOrServices.transactions.transactionInstallmentGracePeriodDate']),
                              minDate: new Date(transactionHistory['enrolledCourseOrServices.transactions.transactionInstallmentGracePeriodDate']),
                          }}
                          onChange={(selectedDates, dateStr, instance) => {
                              // changeEvent("training", idx, "trainingEndDate", dateStr);
                              setTransactionInstallmentGracePeriod(dateStr)
                          }}
                      />
                      </div>
                    </Row>
                  </Col>
                  <Col md={4} style={{textAlign:"right"}}>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=>{extendGracePeriod()}}
                  >
                    <i className="mdi mdi-update font-size-14 align-middle me-2"></i>{" "}
                      Extend Grace Period
                    </button>{" "}
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <Row className="mb-3">
                      <label className="col-md-4 col-form-label">Note</label>
                      <div className="col-md-8">
                      <textarea 
                        className="form-control d-block" 
                        style={{width:"100%"}} 
                        placeholder="If you want to add something in note quote."
                        value={mailNote}
                        onChange={e=>{setMailNote(e.target.value)}}
                      >
                      </textarea>
                      </div>
                    </Row>
                  </Col>
                  <Col md={4} style={{textAlign:"right"}}>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={()=>{sendGracePeriodExtensionMail()}}
                  >
                    <i className="mdi mdi-email-send-outline font-size-14 align-middle me-2"></i>{" "}
                      Send Overdue Mail
                    </button>{" "}
                  </Col>
                </Row>
                </>}
          </CardBody>
            </Card>}
        </Row>
    )
}

export default PaymentPolicy