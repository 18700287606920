import React, { useState, useEffect, useRef } from "react"


import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"

//Import Flatepicker
import Axios from "../../../helpers/axios_helper"
import * as CHelper from "../../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import ServiceByParticipant from "../../Service/serviceByParticipant"
import EnrollByParticipant from "../../Service/enrollByParticipant"
import "flatpickr/dist/themes/material_blue.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"


const Model = (props) => {
    const [activeTab, setactiveTab] = useState(1)
    const [updateData, setUpdateData] = useState(false)
    const [academicProfileId, setAcademicProfileId] =useState(false);
    
    useEffect(()=>{
        if(props.userDetailsAll.userDetail){
            setAcademicProfileId(props.userDetailsAll.userDetail.academicProfileId)
        }
    },[props.userDetailsAll])

    return (
        <Row>
            {(!props.id || props.id === 0 || (props.id > 0 && updateData)) &&
                <Col xl="12">
                  <Card>
                      <CardBody>
                          <div className="form-wizard-wrapper wizard clearfix">
                              <div className="steps clearfix">
                                  <ul>
                                      <NavItem
                                          style={{width:"50%"}}
                                          className={classnames({ current: activeTab === 1 })}>
                                          <NavLink
                                              className={classnames({ current: activeTab === 1 })}
                                              onClick={() => {
                                                  setactiveTab(1)
                                              }}
                                          >
                                              <span className="number"><i className="mdi mdi-note-text-outline"></i></span>{" "}
                                              Consumed Services
                                          </NavLink>
                                      </NavItem>
                                      <NavItem 
                                      style={{width:"50%"}}
                                      className={classnames({ current: activeTab === 4 })}>
                                          <NavLink
                                              className={classnames({ active: activeTab === 4 })}
                                              onClick={() => {
                                                  setactiveTab(4)
                                              }}
                                          >
                                              <span className="number"><i className="mdi mdi-note-text-outline"></i></span>
                                              Want to Enroll Service
                                          </NavLink>
                                      </NavItem>
                                  </ul>
                              </div>
                              <div className="content clearfix">
                                  <TabContent
                                      activeTab={activeTab}
                                      className="body"
                                  >
                                      <TabPane tabId={1}>
                                          <ServiceByParticipant academicProfileId={academicProfileId}/>
                                      </TabPane>
                                      <TabPane tabId={4}>
                                          <EnrollByParticipant academicProfileId={academicProfileId}/>
                                      </TabPane>
                                  </TabContent>
                              </div>
                          </div>
                      </CardBody>
                  </Card>
                </Col>
            }
        </Row>
    )
}

export default Model
