import PropTypes from 'prop-types'
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"
import * as Session from "../../helpers/session_helper"
import config from '../../config'

const Logout = props => {
  useEffect(() => {
    Session.getSession().then((data)=>{
      if(data && data.fromWeb){
        Session.removeSession().then(()=>{
          window.location.replace(`${config.applicationSettings.webBaseUrl}/sign-out`)
        });
      }
      else{
        Session.removeSession().then(()=>{
          props.logoutUser(props.history)
        });
      }
    });
    
  })

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func
}

export default withRouter(connect(null, { logoutUser })(Logout))
