import PropTypes from "prop-types"
import React,{useState, useEffect} from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import AdminSidebarContent from "./SidebarContent/AdminSidebarContent"
import AcademicSidebarContent from "./SidebarContent/AcademicSidebarContent"
import InstructorSidebarContent from "./SidebarContent/InstructorSidebarContent"
import ParticipantSidebarContent from "./SidebarContent/ParticipantSidebarContent"
import UserSidebarContent from "./SidebarContent/UserSidebarContent"
import AlumniSidebarContent from "./SidebarContent/AlumniSidebarContent"
import OtherSidebarContent from "./SidebarContent/OtherSidebarContent"
import PaymentCOSidebarContent from "./SidebarContent/PaymentCOSidebarContent"
import ServiceCOSidebarContent from "./SidebarContent/ServiceCOSidebarContent"

import avatar2 from "../../assets/images/users/avatar.png"
import * as Session from "../../helpers/session_helper"

const Sidebar = (props) => {

  const [adminUser, setAdminUser ]=useState(false);
  const [academicUser, setAcademicUser ]=useState(false);
  const [instructorUser, setInstructorUser ]=useState(false);
  const [participantUser, setParticipantUser ]=useState(false);
  const [userUser, setUserUser ]=useState(false);
  const [alumniUser, setAlumniUser ]=useState(false);
  const [paymentCoUser, setPaymentCoUser ]=useState(false);
  const [serviceCoUser, setServiceCoUser ]=useState(false);
  const [other, setOther ]=useState(false);

  useEffect(() => {
    const data=Session.getUser()
    if(data && data.role_code=='111') {
      setAdminUser(true)
    }
    else if(data && data.role_code=='222') {
      setAcademicUser(true)
    }
    else if(data && data.role_code=='333') {
      setInstructorUser(true)
    }
    else if(data && data.role_code=='444') {
      setParticipantUser(true)
    }
    else if(data && data.role_code=='555') {
      setAlumniUser(true)
    }
    else if(data && data.role_code=='666') {
      setUserUser(true)
    }
    else if(data && data.role_code=='777') {
      setPaymentCoUser(true)
    }
    else if(data && data.role_code=='888') {
      setServiceCoUser(true)
    }
    else if(data) {
      setOther(true)
    }
    
  }, []);

  return (
    <>
    {!participantUser && !instructorUser &&
      <div className="vertical-menu">
        <div className="h-100">
          <div className="user-wid text-center py-4">
            <div className="user-img">
              <img src={Session.getUser().profilePicture ?? avatar2} alt="" className="avatar-md mx-auto rounded-circle" />
            </div>

              {participantUser ? 
              <div className="mt-3">
                <Link to="#" className="text-dark fw-medium font-size-16">{Session.getUser().full_name}</Link>
                {/* <p className="text-body mt-1 mb-0 font-size-13">Active Courses/Trainings: <b>1</b></p> */}
              </div>
              :
              <div className="mt-3">
                <Link to="#" className="text-dark fw-medium font-size-16">{Session.getUser().full_name}</Link>
                <p className="text-body mt-1 mb-0 font-size-13">{Session.getUser().role_name}</p>
              </div>
              }
            </div>
            <div data-simplebar className="h-100">

            {/* {adminUser ? 
            props.type !== "condensed" ? <AdminSidebarContent /> : <AdminSidebarContent />
            : 
            props.type !== "condensed" ? <UserSidebarContent /> : <UserSidebarContent />} */}
            

            {adminUser && props.type !== "condensed" && <AdminSidebarContent /> }
            {academicUser && props.type !== "condensed" && <AcademicSidebarContent /> }
            {instructorUser && props.type !== "condensed" && <InstructorSidebarContent /> }
            {participantUser && props.type !== "condensed" && <ParticipantSidebarContent /> }
            {userUser && props.type !== "condensed" && <UserSidebarContent /> }
            {alumniUser && props.type !== "condensed" && <AlumniSidebarContent /> }
            {paymentCoUser && props.type !== "condensed" && <PaymentCOSidebarContent />}
            {serviceCoUser && props.type !== "condensed" && <ServiceCOSidebarContent />}
            {other && props.type !== "condensed" && <OtherSidebarContent />}
          </div>
          </div>
        </div>
    }
    </>
  )
}

Sidebar.propTypes = {
        type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
        layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
      {}
)(withRouter(withTranslation()(Sidebar)))