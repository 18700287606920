import * as React from "react";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import mediaImage from "../../assets/images/users/avatar.jpg";
import mediaSignatureImage from "../../assets/images/users/signature.png";

export const ComponentToPrint = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className="print-container">
      <style type="text/css" media="print">
        {`
          @page {
            size: A4 portrait;
            margin: 10mm;
          }

          body {
            font-family: 'Times New Roman', serif;
            -webkit-print-color-adjust: exact;
          }

          .print-container {
            font-family: 'Georgia', serif;
            color: #333;
            line-height: 1.6;
            padding: 10px;
            max-width: 1000px;
            margin: auto;
          }

          header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            text-align: left;
          }

          header img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin-right: 20px;
            border: 3px solid #0056b3;
          }

          header div {
            flex: 1;
          }

          header h1 {
            font-size: 2.5em;
            font-weight: bold;
            color: #004085;
            margin-bottom: 10px;
          }

          header p {
            font-size: 1.2em;
            color: #6c757d;
          }

          .contact-info {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 0;
            color: #6c757d;
          }

          .contact-info span {
            display: flex;
            align-items: center;
            gap: 5px;
            font-size: 1em;
          }

          section {
            margin-bottom: 25px;
          }

          section h2 {
            font-size: 1.8em;
            font-weight: bold;
            color: #0056b3;
            border-bottom: 2px solid #0056b3;
            margin-bottom: 15px;
          }

          section h3 {
            font-size: 1.2em;
            color: #343a40;
            font-weight: 600;
          }

          section p {
            margin: 0;
            color: #495057;
          }

          ul {
            margin: 0;
            padding-left: 20px;
            list-style-type: disc;
          }

          ul li {
            margin-bottom: 5px;
            color: #495057;
          }

          .tags span {
            display: inline-block;
            background: #f8f9fa;
            color: #6c757d;
            padding: 5px 10px;
            border-radius: 5px;
            margin: 5px 5px 0 0;
            font-size: 0.9em;
          }

          .signature {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 50px;
            gap: 10px;
          }

          .signature img {
            width: 150px;
            height: auto;
          }

          .signature div {
            text-align: right;
            color: #495057;
            font-size: 1em;
          }
        `}
      </style>

      {/* Header */}
      <header>
        <img
          src={props.academicProfile?.academicProfileFiles && props.academicProfile?.academicProfileFiles.length>0 ? props.academicProfile?.academicProfileFiles[0].link : mediaImage}//"https://via.placeholder.com/150" // Replace with profile picture URL
          alt="Profile"
        />
        <div>
          <h1>{props.academicProfile?.academicFirstName} {props.academicProfile?.academicLastName}</h1>
          <div className="contact-info">
            <span>
              <EmailIcon /> {props.academicProfile?.academicEmail}
            </span>
            <span>
              <PhoneIcon /> {props.academicProfile?.academicContactNo}
            </span>
          </div>
        </div>
      </header>
      <p>{props.academicProfile?.academicBiography}</p>

      {/* Address Section */}
      <section>
        <h2>Address</h2>
        <div className="row" style={{ display: "flex", gap: "20px" }}>
          {props.academicProfile?.addressDetails?.map((item, i) => {
            const upazillaLabel =
              props.upazillas?.find((x) => x.value === item.addressDetailUpazilaId)
                ?.label || null;
            const zillaLabel =
              props.zillas?.find((x) => x.value === item.addressDetailDistrictId)
                ?.label || null;
            const divisionLabel =
              props.divisions?.find((x) => x.value === item.addressDetailDivisionId)
                ?.label || null;

            return (
              <div key={i} className="col" style={{ paddingTop: "10px" }}>
                <h3 style={{ padding: 0 }}>{i === 0 ? "Present" : "Permanent"}</h3>
                <p>
                  {upazillaLabel}
                  {zillaLabel ? `, ${zillaLabel}` : ""}
                  {divisionLabel ? `, ${divisionLabel}` : ""}
                </p>
              </div>
            );
          })}
        </div>

      </section>

      {/* Education Section */}
      {props.academicProfile.educations.length>0 &&
      <section>
        <h2>Education</h2>
        {props.academicProfile.educations.map((item,i)=>{
          return (
            <div key={i}>
              <h3>{item.educationDegree} ({item.educationDegreeName})</h3>
              <p>{item.educationInstitute}, {item.educationEndDate}</p>
              <p>
                {item.educationStatus}
              </p>
            </div>
          )
        })}
      </section>}

      {/* Experiences Section */}
      {props.academicProfile.experiences.length>0 &&
      <section>
        <h2>Experiences</h2>
        <ul>
          {props.academicProfile.experiences.map((item,i)=>{
            return (
              <li>
                <strong>{item.experienceDesignation}</strong>, {item.experienceOrganization}, 
                <em>{item.experienceIndustry}</em>, ({item.experienceStartDate.split('-')[0]}-{item.experienceContinuing?"Running":item.experienceEndDate.split('-')[0]}).
              </li>
            )
          })}
        </ul>
      </section>}

      {/* Experiences Section */}
      {props.academicProfile.trainings.length>0 &&
      <section>
        <h2>Trainings</h2>
        <ul>
          {props.academicProfile.trainings.map((item,i)=>{
            return (
              <li>
                <strong>{item.trainingTitle}</strong>, {item.trainingInstitution}, 
                <em>{item.trainingStatus}</em>
                {/*, ({item.trainingStartDate.split('-')[0]}-{item.trainingEndDate.split('-')[0]}). */}
              </li>
            )
          })}
        </ul>
      </section>}

      {/* Certification Section */}
      {props.academicProfile.certifications.length>0 &&
      <section>
        <h2>Certification</h2>
        <ul>
          {props.academicProfile.certifications.map((item,i)=>{
            return (
              <li>
                <strong>{item.certificationName}</strong>, {item.certificationInstitution}, 
                <em>{item.certificationYear}</em>
              </li>
            )
          })}
        </ul>
      </section>}

      {/* Signature Section */}
      <div className="signature">
        <img
          src={props.academicProfile?.academicProfileSignatureFiles && props.academicProfile?.academicProfileSignatureFiles.length>0 ? props.academicProfile?.academicProfileSignatureFiles[0].link : mediaSignatureImage}
          alt="Signature"
          style={{maxHeight:"70px"}}
        />
        <div>
        </div>
      </div>
    </div>
  );
});
