import React, {useState, useEffect} from "react"
import { Card, CardBody, Table, CardTitle, Col, Button } from "reactstrap"
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import * as SHelper from "../../helpers/session_helper"
import { toast, Slide } from 'react-toastify/dist/react-toastify';

const PaymentPolicy = ({paymentPolicyData,transactions,academicProfileId, courseId, userHasEditPermission, academicProfileIsActive, checkCampusAndTimeSelection}) => {
    const [paymentPolicy, setPaymentPolicy] = useState(false);
    const [transactionHistory, setTransactionsHistory] = useState(false);
    const [oneTimePayment, setOneTimePayment] = useState(false);
    useEffect(()=>{
        setPaymentPolicy(false);
        setTransactionsHistory(false);
        if(paymentPolicyData && courseId && academicProfileId){
            setPaymentPolicy(paymentPolicyData);
            let installments = paymentPolicyData.filter(x=>x.transactionType==2);
            if(installments.length==1){
                setOneTimePayment(true);
            }
        }
        else{
            setPaymentPolicy(false);
        }
        if(transactions && courseId && academicProfileId){
            // if(!SHelper.getUser()){
            //     transactions = transactions.filter(x=>String(x.enrolledCourseId)==String(courseId))
            // }
            // if(transactions.length>0){
                setTransactionsHistory(transactions);
                let installments = transactions.filter(x=>x.transactionType==2);
                if(installments.length==1){
                    setOneTimePayment(true);
                }
            // }
        }
        else{
            setTransactionsHistory(false);
        }
        return;
    },[paymentPolicyData,transactions])

    const openPaymentWindow = (paymentUrl) => {
        window.location.href = paymentUrl;
    };

    async function initiatePayment(applicationFee, installmentNo, initialPayment =false){
        const checkCampusAndTimeSelectionOk = checkCampusAndTimeSelection()
        if(checkCampusAndTimeSelectionOk ){
            window.sLoader();

            const user=SHelper.getUser();
            let isParticipant = false;

            if(user && user.role_code=='444') {
                isParticipant = true;
            }

            const successUrl = JSON.stringify(
                {
                    academicProfileId:academicProfileId,
                    courseId:courseId,
                    activeTab:6,
                    applicationFee:applicationFee,
                    installmentNo:installmentNo,
                    campus: checkCampusAndTimeSelectionOk.campus,
                    classTime: checkCampusAndTimeSelectionOk.classTime
                }
            )

            const jsonData = {
                academicProfileId: academicProfileId,
                courseId: courseId,
                applicationFee : applicationFee,
                installmentFee: !applicationFee,
                installmentNo: installmentNo,
                successUrlParams: encodeURIComponent(CHelper.encryptData(successUrl)),
                value_a:`academicProfileId_${academicProfileId}`,
                value_b:`courseId_${courseId}`,
                value_c:`applicationFee_${applicationFee?1:0}`,
                value_d:`installmentNo_${installmentNo}`,
                isParticipant: isParticipant??false,
                initialPayment: initialPayment,
                campus: checkCampusAndTimeSelectionOk.campus,
                classTime: checkCampusAndTimeSelectionOk.classTime
            }

            await Axios.post(`/open/sslcommerz/init`, jsonData)
                .then(async (response) => {
                    if (response.data.status === 200) {
                        openPaymentWindow(decodeURIComponent(response.data.message));
                    } else {
                        window.hLoader();
                        toast.error(response.data.message, {
                            position: toast.POSITION.TOP_RIGHT,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((e) => {
                    window.hLoader();
                    toast.error(e, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 3000,
                    });
                })
        }
    }

    const TransactionStatus = ({transactionStatus, initialPayment}) =>{
        return (
            <>
                {transactionStatus==1 && <span className="badge badge-soft-secondary font-size-12">{initialPayment ? "-" : "UnPaid"}</span>}
                {transactionStatus==2 && <span className="badge badge-soft-warning font-size-12">Processing</span>}
                {transactionStatus==3 && <span className="badge badge-soft-info font-size-12">Checking</span>}
                {transactionStatus==4 && <span className="badge badge-soft-success font-size-12">Confirmed</span>}
                {transactionStatus==5 && <span className="badge badge-soft-danger font-size-12">Denied From SSL</span>}
                {transactionStatus==6 && <span className="badge badge-soft-danger font-size-12">Failed</span>}
                {transactionStatus==7 && <span className="badge badge-soft-danger font-size-12">Cancelled</span>}
            </>
        )
    }

    const TransactionButton = ({transactionDetail,transactionNo, initialPayment}) =>{
        return (
            <>
                {transactionDetail.transactionStatus==1 && !userHasEditPermission && ((!academicProfileIsActive && transactionNo == 0) || academicProfileIsActive) && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo,initialPayment)} className="btn btn-info btn-sm">Pay now</Button>}
                {transactionDetail.transactionStatus==2 && !userHasEditPermission && ((!academicProfileIsActive && transactionNo == 0) || academicProfileIsActive) && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo,initialPayment)} className="btn btn-warning btn-sm">Pay Again</Button>}
                {/* {transactionDetail.transactionStatus==3 && !userHasEditPermission && ((!academicProfileIsActive && transactionNo == 0) || academicProfileIsActive) && <Button onClick={()=> {}} className="btn btn-warning btn-sm" disabled={true}>Checking</Button>} */}
                {/* {transactionDetail.transactionStatus==4 && !userHasEditPermission && ((!academicProfileIsActive && transactionNo == 0) || academicProfileIsActive) && <Button onClick={()=> {}} className="btn btn-success btn-sm" disabled={true}>Paid</Button>} */}
                {transactionDetail.transactionStatus==5 && !userHasEditPermission && ((!academicProfileIsActive && transactionNo == 0) || academicProfileIsActive) && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo, initialPayment)} className="btn btn-info btn-sm">Pay Again</Button>}
                {transactionDetail.transactionStatus==6 && !userHasEditPermission && ((!academicProfileIsActive && transactionNo == 0) || academicProfileIsActive) && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo, initialPayment)} className="btn btn-info btn-sm">Pay now</Button>}
                {transactionDetail.transactionStatus==7 && !userHasEditPermission && ((!academicProfileIsActive && transactionNo == 0) || academicProfileIsActive) && <Button onClick={()=>initiatePayment(transactionDetail.transactionType==1,transactionDetail.transactionInstallmentNo, initialPayment)} className="btn btn-info btn-sm">Pay now</Button>}
            </>
            
        )
    }

    return (
        <Col lg={12}>
            {paymentPolicy && paymentPolicy.length>0 && 
            <Card>
                <CardBody style={{paddingLeft:"0",paddingRight:"0"}}>
                    <CardTitle className="h4 mb-4">Payment Section</CardTitle>
                    <div className="table-responsive">
                        <Table className="table-centered">
                            <thead style={{ backgroundColor: "#3b5de7", color: "white", textAlign: "left" }}>
                                <tr>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Payment Type
                                    </th>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Transaction Date
                                    </th>
                                    {(academicProfileIsActive || userHasEditPermission) && (
                                    <>
                                        <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                        Next Payment Date
                                        </th>
                                        <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                        Grace Period
                                        </th>
                                    </>
                                    )}
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Amount
                                    </th>
                                    <th
                                    scope="col"
                                    colSpan="2"
                                    style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}
                                    >
                                    Payment Status
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {paymentPolicy.map((item,i)=>{
                                    return ( 
                                        <tr key={i}>
                                            <td>{item.transactionType==1?"Application Fee":oneTimePayment?"Course Fee":"Installment "+item.transactionInstallmentNo}</td>
                                            <td>{item.transactionSSLCommerzConfirmedDate??"-"}</td>
                                            {(academicProfileIsActive || userHasEditPermission) && <>
                                            <td>{item.transactionInstallmentStartDate??"-"}</td>
                                            <td>{item.transactionInstallmentGracePeriod??"-"}</td>
                                            </>}
                                            <td>Tk. {new Intl.NumberFormat('en-US').format(item.transactionInstallmentAmount)}</td>
                                            <td><TransactionStatus transactionStatus={item.transactionStatus}/></td>
                                            <td style={{textAlign:"center"}}><TransactionButton transactionDetail={item} transactionNo={i} initialPayment={true}/></td>
                                        </tr>)
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>}


            {transactionHistory && transactionHistory.length>0 &&
            <Card>
                <CardBody style={{paddingLeft:"0",paddingRight:"0"}}>
                    <CardTitle className="h4 mb-4">Payment Section</CardTitle>
                    <div className="table-responsive">
                        <Table className="table-centered">
                        <thead style={{ backgroundColor: "#3b5de7", color: "white", textAlign: "left" , opacity:0.9}}>
                                <tr>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Payment Type
                                    </th>
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Transaction Date
                                    </th>
                                    {(academicProfileIsActive || userHasEditPermission) && (
                                    <>
                                        <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                        Next Payment Date
                                        </th>
                                        <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                        Grace Period
                                        </th>
                                    </>
                                    )}
                                    <th scope="col" style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}>
                                    Amount
                                    </th>
                                    <th
                                    scope="col"
                                    colSpan="2"
                                    style={{ padding: "12px", fontWeight: "bold", textTransform: "uppercase", fontSize: "14px" }}
                                    >
                                    Payment Status
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/* {transactionHistory.length>1 ?  */}
                                {transactionHistory.map((item,i)=>{
                                    return ( 
                                        <tr key={i}>
                                            <td>{item.transactionType==1?"Application Fee":oneTimePayment?"Course Fee":"Installment "+item.transactionInstallmentNo}</td>
                                            <td>{item.transactionSSLCommerzConfirmedDate??"-"}</td>
                                            {(academicProfileIsActive || userHasEditPermission) && <>
                                            <td>{item.transactionInstallmentStartDate??"-"}</td>
                                            <td>{item.transactionInstallmentGracePeriod??"-"}</td>
                                            </>}
                                            <td>Tk. {new Intl.NumberFormat('en-US').format(item.transactionInstallmentAmount)}</td>
                                            <td><TransactionStatus transactionStatus={item.transactionStatus}/></td>
                                            <td style={{textAlign:"center"}}><TransactionButton transactionDetail={item} transactionNo={i}/></td>
                                        </tr>)
                                    })}
                                    {/* :
                                    <tr>
                                        <td>Course Fee</td>
                                        <td>{transactionHistory[0].perInstallmentAmount} Taka</td>
                                        <td><span className="badge badge-soft-danger font-size-12">Unpaid</span></td>
                                        <td><Button onClick={()=>initiatePayment(false, 0)} className="btn btn-primary btn-sm">Pay now</Button></td>
                                    </tr>} */}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>}

            {((transactionHistory && transactionHistory.length==0) || (paymentPolicy && paymentPolicy.length==0)) &&
                <>
                    <CardTitle style={{textAlign:"center", fontSize:"18px", fontStyle:"bolder", color:"red", marginTop:"30px"}}>No transaction profile/history found for that course !</CardTitle>
                </>
            }
        </Col>
    )
}

export default PaymentPolicy