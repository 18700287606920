import React from "react"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import CreateModel from "./model"

const UserCreate = () => {

  return (
    <>
      <div className="page-content">
          <Breadcrumbs title="Question Bank" breadcrumbItem="New Question" />
          <CreateModel/>
      </div>
    </>
  )
}

export default UserCreate
