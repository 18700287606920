import { useHistory } from "react-router-dom"
import React, { useMemo, useEffect, useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Modal, Input,
  TabContent,
  TabPane,
  NavLink,
  CardTitle,
  Nav,
  NavItem, } from "reactstrap"

  import { toast,Slide } from 'react-toastify/dist/react-toastify';
  import MaterialReactTable from 'material-react-table';
  import { List,Edit,ChangeCircle } from '@mui/icons-material';
  import {
    Box,
    Button,
    IconButton,
    Typography,
    Tooltip,
  } from '@mui/material';
import classnames from "classnames"
//Import Breadcrumb
import TableLoader from "../../components/Common/TableLoader"
import Axios from "../../helpers/axios_helper"

import CustomModal from "../Common/CustomModal"

const DatatableTables = (props) => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  const selectedItem = useRef(0);
  const [modal_center, setmodal_center] = useState(false)
  const [update_status_modal_center, setUpdate_status_modal_center] = useState(false);

  const [columnFilters, setColumnFilters] = useState([]);
  const [columnFilterFns, setColumnFilterFns] = useState({
    courseName: 'contains',
    courseCode: 'contains',
    courseCode: 'contains',
    courseFee: 'contains',
    'courseType.courseTypeName': 'contains'
  });

  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const tableInstanceRef = useRef(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });
  
  //#region Datatable


  const [academicProfileId, setAcademicProfileId] =useState(false);
      
    useEffect(()=>{
        if(props.academicProfileId){
            setAcademicProfileId(props.academicProfileId)
        }
    },[props.academicProfileId])

    useEffect(()=>{
      if(academicProfileId){
          fetchDataFromAPI(true)
      }
  },[academicProfileId])
    
  const columns = useMemo(
    () => [
      {
        size:50,
        accessorKey: 'sl',
        header: "#SL",
        Cell: ({ cell, row }) => (
          // <Typography variant='h7'>{row.original['menuHead.mname']}</Typography>
          <Typography variant='h7'>{cell.getValue()}</Typography>
        ),
        enableColumnFilterModes: false,
        enableColumnActions:false,
        enableSorting: false
      },
      {
        accessorKey: 'service.serviceName',
        header: "Service",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original['service.serviceName']}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        accessorKey: 'service.course.courseName',
        header: "Course",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>{row.original['service.course.courseName']??'-'}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        size:50,
        accessorKey: 'transactions.transactionInstallmentAmount',
        header: "Amount",
        Cell: ({ cell, row }) => (
          <Typography variant='h7'>Tk. {new Intl.NumberFormat('en-US').format(row.original['transactions.transactionInstallmentAmount'])}</Typography>
        ),
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      },
      {
        header: "Transaction Status",
        Cell: ({ cell, row }) => {
          let statusValue = row.original['transactions.transactionStatus'];
          let statusLabel,badgeClass;
          
          switch (statusValue) {
            case '1':
              statusLabel = 'Not Initiated';
              badgeClass = 'badge badge-soft-secondary font-size-12';
              break;
            case '2':
              statusLabel = 'Processing';
              badgeClass = 'badge badge-soft-warning font-size-12';
              break;
            case '3':
              statusLabel = 'IPN Confirmed';
              badgeClass = 'badge badge-soft-info font-size-12';
              break;
            case '4':
              statusLabel = 'Validated';
              badgeClass = 'badge badge-soft-success font-size-12';
              break;
            case '5':
              statusLabel = 'Validation Failed';
              badgeClass = 'badge badge-soft-danger font-size-12';
              break;
            case '6':
              statusLabel = 'Failed';
              badgeClass = 'badge badge-soft-danger font-size-12';
              break;
            case '7':
              statusLabel = 'Canceled';
              badgeClass = 'badge badge-soft-danger font-size-12';
              break;
            default:
              statusLabel = 'N/A';
              badgeClass = 'badge badge-soft-dark font-size-12';
          }
        
          return <span className={badgeClass}>{statusLabel}</span>;
        },
        enableColumnFilterModes: true,
        filterFn: 'contains',
        columnFilterModeOptions: ['contains'],
      }
    ],
    [], 
  );

  const findFilterCondition = (name) => {
    var condition = '';
    if (columnFilterFns[name]) {
      condition = columnFilterFns[name];
    }
    return condition;
  }

  const fetchDataFromAPI = async (reset = false) => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    let filterQuery = '';
    let sorterQuery = '';

    if (!reset) {
      if (columnFilters.length > 0) {
        columnFilters.forEach(element => {
          if (element.id === 'invoicedate' || element.id === 'duedate') {
            // filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${AuthVerify.getDate(element.value)}`;
          }
          else {
            filterQuery += `&filter=${element.id},${findFilterCondition(element.id)},${element.value}`;
          }
        });
      }
      if (sorting.length > 0) {
        sorting.forEach(element => {
          sorterQuery += `&sortby=${element.id}&orderby=${element.desc ? 'desc' : 'asc'}`;
        });
      }
    } else {
      setPagination({
        pageIndex: 0,
        pageSize: 5
      });
      tableInstanceRef.current.resetRowSelection();
    }

    //Get Invoices
    //api/account/invoice
    const url = `/service/byAcademicProfile/${academicProfileId}?limit=${pagination.pageSize}&offset=${pagination.pageIndex * pagination.pageSize}${sorterQuery}${filterQuery}`;
    // let url = `/page/list`;
    await Axios.get(url)
      .then(function (response) {
        setIsError(false);
        setIsLoading(false);
        setIsRefetching(false);
        if (response && response.data?.data) {
          setData(response.data?.data);
          setRowCount(response.data.recordsTotal);
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
  };

  useEffect(() => {
    fetchDataFromAPI();
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting
  ]);

  useEffect(() => {
    if(academicProfileId){
      fetchDataFromAPI(true);
    }
  }, [academicProfileId]);

  //#endregion

  //#region Modal

  const handleCallback = (response) =>{
    selectedItem.current=0;
    setmodal_center(false);
    fetchDataFromAPI(true);
  }

  const modalCallbackStatus = async (result) =>{
    if(result){
      updateActiveStatus(selectedItem.current);
    }
    setUpdate_status_modal_center(false);
    selectedItem.current=0;
  }

  const updateActiveStatus = async (id) =>{
    const url = `/course/changeStatus/${id}`;
    await Axios.patch(url)
      .then(function (response) {
        fetchDataFromAPI(true);
        if (response && response.data?.status==200) {
          document.getElementById(`courseIsActive${id}`).innerHTML=response.data.courseIsActive==1?"True":"False"
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
        else {
          toast.error(response.statusText ?? "Data fetching failed !", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
            transition: Slide,
          });
        }
      })
      .catch(function (error) {
        // navigate('/');
      });
  }

  //#endregion

  return (
    <>
        <Row>
          <Col className="col-12" style={{padding:"0"}}>
            <Card style={{padding:"0"}}>
              <CardBody  style={{padding:"0"}}>
                {true ?
                  <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableColumnActions={false}
                    enableGlobalFilter={false}
                    enableColumnFilterModes
                    initialState={{
                      showColumnFilters: false
                    }}
                    manualFiltering
                    manualPagination
                    manualSorting
                    onColumnFilterFnsChange={setColumnFilterFns}
                    onColumnFiltersChange={setColumnFilters}
                    onGlobalFilterChange={setGlobalFilter}
                    onPaginationChange={setPagination}
                    onSortingChange={setSorting}
                    tableInstanceRef={tableInstanceRef}
                    rowCount={rowCount}
                    // enableEditing
                    state={{
                      columnFilters,
                      columnFilterFns,
                      globalFilter,
                      isLoading,
                      pagination,
                      showAlertBanner: isError,
                      showProgressBars: isRefetching,
                      sorting,
                    }}
                    positionActionsColumn="last"
                    displayColumnDefOptions={{
                      'mrt-row-actions': {
                        header: 'Action',
                      },
                    }}
                    renderTopToolbarCustomActions={() => (
                      <Box>
                        <Button
                          className="btn-sm"
                          variant="outlined"
                          color="primary"
                          onClick={() => {
                            // Trigger your data refresh logic here
                            fetchDataFromAPI(true);
                          }}
                        >
                          <i className="mdi mdi-refresh"></i>
                        </Button>
                      </Box>
                    )}
                    // renderRowActions={({ row, table }) => (
                    //   <Box sx={{ display: 'flex' }}>
                    //     <Tooltip arrow placement="left" title="Details">
                    //       <IconButton onClick={() => {}}>
                    //         <List />
                    //       </IconButton>
                    //     </Tooltip>
                    //   </Box>
                    // )}
                  />
                  :
                  <TableLoader />
                }
              </CardBody>
            </Card>
          </Col>
          <Modal
            size="xl"
            isOpen={modal_center}
            centered={true}>

            <div className="modal-header">
              <h5 className="modal-title mt-0">Update Course</h5>
              <button
                type="button"
                onClick={handleCallback}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              > 
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{padding:"0"}}>
              {/* <CourseTypeModal id={selectedItem.current} handleCallback={handleCallback}/> */}
            </div>
          </Modal>

          <CustomModal modelShow={update_status_modal_center} handleCallback={modalCallbackStatus}/>
          {/* <CustomModal modelShow={reset_modal_center} handleCallback={modalCallbackReset} bodyMsg={"New password will sent to the user through user email address."}/> */}
        </Row>
    </>
  )
}

export default DatatableTables
