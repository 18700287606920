import React, { useState, useEffect, useRef } from "react"


import {
    Label,
    Card,
    CardBody,
    Button,
    Col,
    Form,
    Input,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    CardTitle,
    CardImg,
} from "reactstrap"
import classnames from "classnames"
import { AvForm, AvField } from "availity-reactstrap-validation"
import Breadcrumbs from "../../components/Common/Breadcrumb"
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import Select from "react-select"

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from 'draft-js-import-html';
import Axios from "../../helpers/axios_helper"
import * as CHelper from "../../helpers/custom_helper"
import { useHistory, Link } from "react-router-dom"
import { toast } from 'react-toastify/dist/react-toastify';
import mediaImage from "../../assets/images/media.jpeg";
import {baseUrl} from '../../helpers/custom_helper';
import './model.scss'
const moment = require('moment');

const Model = (props) => {
    const [updateData, setUpdateData] = useState(false)
    const [file, setFile] = useState([]);

    const fileChangeEvent = (event) => {
        let documentFile = event.target.files[0];
        setFile(documentFile);
        // setFileOriginalName(documentFile.name)
    }

    const handleSubmit = async (event, errors, values) => {
        window.sLoader();
        const formData = new FormData();
        formData.append('file', file);
        await Axios.post(`/question-bank/bulk-upload`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
        })
        .then(response => {
            if(response.data.status==200){
                toast.success('File Created/Updated successfully!', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
            else{
                toast.error('File Uploaded Failed !', {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 3000,
                });
            }
            
            // fetchData();
        })
        .catch(function (error) {
            toast.error("File Uploaded Failed !", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 3000,
            });
        });

        window.hLoader();
    }

    return (
    <div className="page-content">
        <Breadcrumbs title="Question Bank" breadcrumbItem="Bulk Entry" />
        <Row>
        {(!props.id || props.id===0 || (props.id>0 && updateData) ) &&
        <Col xl="12">
            <Card>
                <CardBody>
                    <AvForm className="needs-validation" onSubmit={handleSubmit}>
                    <Row>
                        <Col md="12">
                            <div className="mb-3">
                                <Label>Select your excel file</Label>
                                <div className="input-group">
                                <Input type="file" className="form-control" id="inputGroupFile02" onChange={fileChangeEvent}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Col style={{textAlign: 'right'}}>
                    <Button color="secondary" type="button" onClick={() => CHelper.downloadFileFromLink(`${baseUrl()}assets/Question Bank Data Template.xlsx`)} style={{float:"left"}}>
                        <i className="fa fa-download"></i> Download Template
                    </Button>
                    {" "}
                    <Button color="primary" type="submit">
                        Submit
                    </Button>
                    </Col>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>
        }
        </Row>
    </div>
    )
}

export default Model
